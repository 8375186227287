import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import SectionHeader from '@components/SectionHeader';
import styles from './culture.module.scss';

const Culture = ({ sectionHeader: { eyebrow, title, description } }) => {
    return (
        <Section variant="dark" dataTheme="dark" className={styles.section}>
            <Grid>
                <Row>
                    <Column>
                        <SectionHeader
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.headerTitle}
                            eyebrow={eyebrow}
                            sizeTitle="h3"
                            variant="dark"
                            title={title}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column className={styles.descriptionColumn}>
                        <Text as="p" size="paragraphMd" color="white">
                            {description}
                        </Text>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Culture.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
};

export default Culture;
