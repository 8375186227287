import React from 'react';
import Section from '@components/core/Section';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './header.module.scss';

const Header = ({ sectionHeader: { eyebrow, title }, mainImage, logo }) => {
    const { md } = useBreakpoint();
    const renderLogo = () => {
        if (!logo) {
            return null;
        }

        if (!logo.childImageSharp && logo.extension === 'svg') {
            return <img className={styles.logo} src={logo.publicURL} alt="" />;
        }

        return (
            <Image className={styles.logo} fluid={logo.childImageSharp.fluid} />
        );
    };

    return (
        <Section
            className={styles.header}
            variant={md ? 'light' : 'dark'}
            dataTheme="dark"
        >
            <div className={styles.sectionDark} />
            <Grid>
                <Row>
                    <Column md="9">
                        <SectionHeader
                            variant="dark"
                            className={styles.sectionHeader}
                            eyebrow={eyebrow}
                            title={title}
                            sizeTitle="h2"
                        />
                    </Column>
                    {logo && (
                        <Column offsetMd="1" md="2">
                            {renderLogo()}
                        </Column>
                    )}
                </Row>
                {mainImage && (
                    <div className={styles.imageContainer}>
                        <div className={styles.imageSquare}>
                            <Image
                                className={styles.image}
                                fluid={mainImage.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                )}
            </Grid>
        </Section>
    );
};

Header.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    mainImage: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
    logo: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
        extension: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default Header;
