import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Eyebrow from '@components/core/Eyebrow';
import Markdown from 'react-remarkable';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './engagement.module.scss';

const Engagement = ({
    sectionHeader: { eyebrow, title, description },
    image,
}) => {
    const breakpoints = useBreakpoint();
    return (
        <Section variant="light" dataTheme="light" className={styles.section}>
            <Grid>
                <Row>
                    <Column>
                        <Eyebrow
                            casing="uppercase"
                            size="paragraphSm"
                            data-testid="section-header-eyebrow"
                        >
                            {eyebrow}
                        </Eyebrow>
                    </Column>
                </Row>
                <Row>
                    <Column md="5">
                        <Text
                            size="h3"
                            as="h3"
                            data-testid="section-header-title"
                            className={styles.headerTitle}
                        >
                            {title}
                        </Text>
                    </Column>
                    <Column md="6" offsetMd="1">
                        <Text as="div" size="paragraphMd">
                            <Markdown>{description}</Markdown>
                        </Text>
                    </Column>
                </Row>
            </Grid>
            <Grid maxWidthFull={!breakpoints.md}>
                <div className={styles.image}>
                    <Img fluid={image.childImageSharp.fluid} />
                </div>
            </Grid>
        </Section>
    );
};

Engagement.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.objectOf(PropTypes.any),
        }),
    }).isRequired,
};

export default Engagement;
