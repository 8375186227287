import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import PropTypes from 'prop-types';
import SectionHeader from '@components/SectionHeader';
import Img from 'gatsby-image';
import styles from './workplace.module.scss';

const Workplace = ({
    sectionHeader: { eyebrow, title, description },
    image,
}) => {
    return (
        <Section variant="light" dataTheme="light" className={styles.section}>
            <Grid>
                <Row className={styles.invertedRow}>
                    <Column md="6">
                        <Img
                            fluid={image.childImageSharp.fluid}
                            className={styles.image}
                        />
                    </Column>
                    <Column md="5" offsetMd="1">
                        <SectionHeader
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.headerTitle}
                            eyebrow={eyebrow}
                            sizeTitle="h3"
                            variant="light"
                            title={title}
                            summary={description}
                        />
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Workplace.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.objectOf(PropTypes.any),
        }),
    }).isRequired,
};

export default Workplace;
