import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import SectionHeader from '@components/SectionHeader';
import styles from './capabilities.module.scss';

// TODO - rename file and dependencies to the correct section name
const Capabilities = ({
    sectionHeader: { eyebrow, title, description },
    image,
}) => {
    return (
        <Section variant="dark" dataTheme="dark" className={styles.section}>
            <Grid>
                <Row className={styles.alignCenter}>
                    <Column md="5">
                        <SectionHeader
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.headerTitle}
                            eyebrow={eyebrow}
                            sizeTitle="h3"
                            variant="dark"
                            title={title}
                            summary={description}
                        />
                    </Column>
                    <Column md="5" offsetMd="2">
                        <div>
                            <Img fluid={image.childImageSharp.fluid} />
                        </div>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Capabilities.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.objectOf(PropTypes.any),
        }),
    }).isRequired,
};

export default Capabilities;
