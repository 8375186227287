import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import SectionHeader from '@components/SectionHeader';
import styles from './partnership.module.scss';

const Partnership = ({ sectionHeader: { eyebrow, title, description } }) => {
    return (
        <Section variant="light" dataTheme="light" className={styles.section}>
            <Grid>
                <Row>
                    <Column md="4">
                        <SectionHeader
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.headerTitle}
                            eyebrow={eyebrow}
                            variant="light"
                            sizeTitle="h3"
                            title={title}
                        />
                    </Column>
                    <Column
                        md="6"
                        offsetMd="2"
                        className={styles.descriptionColumn}
                    >
                        <Text as="p" size="paragraphMd" color="black">
                            {description}
                        </Text>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Partnership.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
};

export default Partnership;
